import React from 'react';
import { Col, Tag } from 'antd';
import { connect } from 'react-redux';
import { searchDocs } from '../../../state/actions/searches';
import { useAuth0 } from '@auth0/auth0-react';

function ColTagList(props) {
  const { searchDocs, tag, pageSize } = props;
  const authState = useAuth0();

  return (
    <Col key={tag} flex={'0 0 auto'} className="columnTag">
      <Tag
        className="innerTag"
        data-testid="doc-tag"
        onClick={() => {
          searchDocs(tag, authState, 1, pageSize);
        }}
      >
        {tag}
      </Tag>
    </Col>
  );
}
const mapStateToProps = state => ({
  pageSize: state.searches.pageSize,
});

export default connect(mapStateToProps, { searchDocs })(ColTagList);
