import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const RenderAdmin = props => {
  const { isAuthenticated } = useAuth0();

  return (
    <div>
      <p>
        <Link to="/">Home</Link>
      </p>
      {isAuthenticated ??
        props.data.map(item => (
          <figure key={item.id}>
            <img src={item.avatarUrl} alt={item.name} crossOrigin="anonymous" />
            <figcaption>
              <h3>{item.name}</h3>
            </figcaption>
          </figure>
        ))}
    </div>
  );
};

export default RenderAdmin;

// Don't forget your prop types! It will save you a lot of debugging headache as you add more features.
RenderAdmin.propTypes = {
  data: PropTypes.arrayOf(
    // Here is an example of enforcing an object structure that we expect to receive in our props:
    PropTypes.shape({
      // Here we require an id of type number or string to prevent a "unique key prop" warning
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string,
      email: PropTypes.string,
      avatar: PropTypes.string,
    })
  ).isRequired,
};
