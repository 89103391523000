import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import reducer from './state/reducers';
import 'antd/dist/antd.less';
import { NotFoundPage } from './components/pages/NotFound';
import { Admin } from './components/pages/Admin';
import { LoginPage } from './components/pages/Login';
import { LandingPage } from './components/pages/Landing';
import { LoadingComponent, Header } from './components/common';
import './reset.css';

const store = createStore(reducer, applyMiddleware(thunk, logger));
ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    }}
    scope="email openid profile"
    responseType="token id_token"
  >
    <Provider store={store}>
      <Router>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </Router>
    </Provider>
  </Auth0Provider>,
  document.getElementById('root')
);

function App() {
  const { isAuthenticated } = useAuth0();
  return (
    <>
      <Header />
      {!isAuthenticated && (
        <div
          style={{
            margin: 'auto',
            position: 'absolute',
            left: '50%',
            top: '50%',
          }}
        >
          Please Log In
        </div>
      )}
      {isAuthenticated && (
        <Switch>
          {/* <Route path="/login" component={LoginPage} /> */}
          {/* any of the routes you need secured should be registered as SecureRoutes */}
          <Route
            path="/"
            exact
            component={() => (
              <LandingPage LoadingComponent={LoadingComponent} />
            )}
          />
          <Route path="/admin" component={Admin} />
          <Route component={NotFoundPage} />
        </Switch>
      )}
    </>
  );
}
