import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getDocs } from '../../state/actions/docs';

const ReduxList = props => {
  const { getDocs, LoadingComponent, RenderItems, isFetching } = props;
  const authState = useAuth0();

  useEffect(() => {
    getDocs(authState, 1, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isFetching ? <LoadingComponent /> : <RenderItems />;
};

ReduxList.propTypes = {
  LoadingComponent: PropTypes.func.isRequired,
  RenderItems: PropTypes.any.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  pageSize: state.searches.pageSize,
  bookmarkedDocs: state.bookmarks.bookmarkedDocs,
});

export default connect(mapStateToProps, { getDocs })(ReduxList);
