import { useAuth0 } from '@auth0/auth0-react';
import { Spin } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

import { ReduxList } from '../../common';
import { getDocs } from '../../../state/actions/docs';
import LandingCardResults from './LandingCardResults';

function RenderLandingPage(props) {
  const { isFetching } = props;

  const authState = useAuth0();
  const { isAuthenticated } = authState;

  return (
    <>
      {isAuthenticated && (
        <ReduxList
          getItemsData={() => getDocs(authState)}
          RenderItems={LandingCardResults}
          LoadingComponent={() => (
            <div
              style={{
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spin size="large" />
            </div>
          )}
          isFetching={isFetching}
        />
      )}
    </>
  );
}

const mapStateToProps = state => ({
  isFetching: state.docs.isFetching,
});

export default connect(mapStateToProps)(RenderLandingPage);
